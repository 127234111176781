<template>
  <div>
    <div class="row mt-5 voucher-home">
      <div class="col-12 col-lg-6 offset-lg-3 dual-box text-white p-4">
        <h3 class="mt-2">Please enter your Company Code below</h3>
        <div class="mb-3">
          <div class="async-error" v-if="this.error">
            {{ this.errorMessage }}
          </div>
          <b-form id="regCampaignCode" @submit.stop.prevent="onSubmit">
            <div class="d-flex">
              <div class="form-group flex-grow-1 mb-0 mr-1">
                <b-form-input
                  class="p-4"
                  id="code-input"
                  name="code-input"
                  v-model="$v.form.code.$model"
                  :state="validateState('code')"
                  placeholder="Company code"
                ></b-form-input>

                <b-form-invalid-feedback style="font-size: small !important"
                  >Company code required</b-form-invalid-feedback
                >
              </div>
              <button class="btn btn-light btn-lg text-hpcolour-green">
                Submit
              </button>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import * as CampaignAccessCache from "./../modules/CampaignAccessCache";
import { VerifyCampaignDetails } from "./../modules/VerifyCampaignDetails";
import { API } from "aws-amplify";
import { resend_voucher } from "./../graphql/queries";
export default {
  mixins: [validationMixin],
  data() {
    return {
      form: {
        code: null,
      },
      error: false,
      errorMessage: null,
    };
  },
  validations: {
    form: {
      code: {
        required,
      },
    },
  },
  methods: {
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.error = false;
      const campaignCode =
        localStorage.hpFluCampaignCode === "undefined"
          ? false
          : localStorage.hpFluCampaignCode;
      const email = localStorage.hpFluEmail;
      if (!campaignCode) {
        await this.onSubmitSendVoucher();
      } else {
        await this.onSubmitResendVoucher(campaignCode, email);
      }
    },
    async onSubmitSendVoucher() {
      try {
        const campaign =
          await CampaignAccessCache.getCampaignAccessCacheByCodeCheckVoucherLimit(
            this.form.code
          );
        const verifyCampaign = new VerifyCampaignDetails(campaign);

        const organisationActive = verifyCampaign.isOrganisationActive();
        if (!organisationActive) {
          throw new Error("This organisation is not active");
        }
        // console.log(campaign, "campaign");

        // const expired = verifyCampaign.hasExpired();
        // if (expired) {
        //   throw new Error("Sorry, campaign has expired");
        // }
        // console.log(expired, "expired");

        const vouchers = verifyCampaign.vouchersAvailable();
        if (vouchers <= 0) {
          throw new Error(
            "Sorry, your organisation have reached their voucher limit."
          );
        }

        var renderEmployeePage = verifyCampaign.renderEmployeePage();
      } catch (e) {
        console.log(e, "error");
        this.errorMessage = e;
        this.error = true;
        return;
      }

      if (renderEmployeePage) {
        this.navigatePage("Employee", { code: this.form.code });
        return;
      }

      // this.navigatePage("PreRegister", {
      //   code: this.form.code,
      // });
      this.navigatePage("Postcode", { code: this.form.code });
      return;
    },
    async onSubmitResendVoucher(campaignCode, email) {
      const that = this;
      const campaignCodeSanitised = campaignCode.toLowerCase().trim();
      const code = this.form.code.toLowerCase().trim();
      if (code !== campaignCodeSanitised) {
        this.error = true;
        that.errorMessage = "Sorry the code is not correct, please try again";
        return;
      }
      try {
        const {
          data: { resend_voucher: voucher },
        } = await API.graphql({
          query: resend_voucher,
          variables: {
            email: email,
            code: campaignCodeSanitised,
            sender: "flu@healthyperformance.co.uk",
            client: "dearspharmacy",
          },
          authMode: "API_KEY",
        });
        if (voucher === null) {
          that.errorMessage =
            "Sorry cannot resend voucher at the moment, please try again";
          that.error = true;
          return;
        }
        this.$router.push({
          name: "Complete",
          params: { code: campaignCodeSanitised },
        });
      } catch (e) {
        // console.log(e, "error");
        that.errorMessage = e;
        that.error = true;
        throw new Error(e);
      }
    },
    navigatePage(page, params) {
      if (params) {
        this.$router.push({ name: page, params: params }).catch(() => {});
        return;
      }
      this.$router.push({ name: page }).catch(() => {});
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
  },
};
</script>
